import { useEffect } from 'react'
import { useState } from 'react'
import { fetchUserLocation } from 'server/fetch/user-location/fetch'
import { mapLanguageWithCountryCode } from 'server/fetch/user-location/mapLanguageToCountryCode'
import { RequestError } from 'utils/fetch/fetchHelper'

export const useGetLocationLanguage = () => {
  const [locationLanguage, setLocationLanguage] = useState<string>()
  useEffect(() => {
    getLocation()
  }, [])

  async function getLocation() {
    try {
      const res = await fetchUserLocation()
      const language = mapLanguageWithCountryCode(res.country_code)
      setLocationLanguage(language)
    } catch (e) {
      const requestError = e as RequestError
      console.error(requestError.reason)
    }
  }

  return {
    locationLanguage,
  }
}
