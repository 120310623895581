export const mapLanguageWithCountryCode = (countryCode: string): string => {
  const spainishCountryCodes = [
    'ES',
    'MX',
    'CO',
    'AR',
    'PE',
    'VE',
    'CL',
    'GL',
    'EC',
    'BO',
    'CU',
    'DO',
    'HN',
    'PY',
    'SV',
    'NI',
    'CR',
    'PA',
    'UY',
    'GQ',
  ]
  const frenchCountryCodes = [
    'BJ',
    'FR',
    'CG',
    'CD',
    'CI',
    'GA',
    'GN',
    'MC',
    'NE',
    'TG',
  ]
  const dutchCountryCodes = ['NL', 'CW', 'SX', 'AW', 'SR']
  const polishCountryCodes = ['PL']

  let locationLanguage = 'EN'

  spainishCountryCodes.map((code) => {
    if (countryCode === code) {
      locationLanguage = 'ES'
    }
  })

  frenchCountryCodes.map((code) => {
    if (countryCode === code) {
      locationLanguage = 'FR'
    }
  })

  dutchCountryCodes.map((code) => {
    if (countryCode === code) {
      locationLanguage = 'NL'
    }
  })

  polishCountryCodes.map((code) => {
    if (countryCode === code) {
      locationLanguage = 'PL'
    }
  })

  return locationLanguage
}
